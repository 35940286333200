export const svgShirts = <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M14.8272 2.38272L11.0741 1L8.90123 3.96296L6.53086 1L2.97531 2.38272L1 7.12346L3.76543 7.91358V15.8148H14.037V7.91358L17 7.12346L14.8272 2.38272Z"
        stroke="currentColor"/>
</svg>;

export const svgHoodies = <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
    <path
        d="M3.76501 8.91358V13.3394M1.72674 13.3394L1.99958 15H3.76501V13.3394M1.72674 13.3394L1 9L2.97489 3.38272L4.75266 3.69136M1.72674 13.3394H3.76501M3.76501 13.3394V16.8148H14.0366V8.91358V15H15.9996L16.9996 9L14.8267 3.38272L12.9502 3.69136M13.9996 13.3394H16.0378M12.9502 3.69136L11.0737 4L9.98723 5.48148M12.9502 3.69136L11 1H6.53044L4.75266 3.69136M4.75266 3.69136L6.53044 4L7.71563 5.48148M9.98723 5.48148L8.90081 6.96296L7.71563 5.48148M9.98723 5.48148V8.91358M7.71563 5.48148V9"
        stroke="currentColor"/>
</svg>;

export const svgCanvas = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 17H1V1H17V17Z" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round"
          strokeLinejoin="round"/>
</svg>;

export const svgMore = <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M8.86646 7.12488L1 12.0002V12.5H17V12.0002L9.13354 7.12488V5.13091C10.4912 5.13091 11.5918 4.20623 11.5918 3.06556C11.5918 1.92488 10.4912 1 9.13354 1C7.77587 1 6.67527 1.92488 6.67527 3.06556"
        stroke="currentColor"/>
</svg>;

export const svgArrow = <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 1L4.99999 5L1 1" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
</svg>;

