import React from 'react'
import PageTop from '../components/PageTop'
function Perks() {
  return (
    <main id="perks">
      {/* <div className="prev_earn d_flex ai_c">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26">
					<circle cx="12.996" cy="13.022" r="11.996" stroke="#fff" transform="rotate(180 12.996 13.022)"/>
					<circle cx="12.982" cy="13.009" r="9.007" stroke="#fff" transform="rotate(180 12.982 13.009)"/>
					<circle cx="12.989" cy="8.506" r="1.501" stroke="#fff" transform="rotate(180 12.989 8.506)"/>
					<path stroke="#fff" d="M12.728 24.991v-2.383m0-19.225V1M2.578 18.913l2.063-1.191m16.626-9.626 2.064-1.192m-20.753.161L4.64 8.256m16.627 9.626 2.064 1.192M11.23 12.258h1.739m1.739 0h-1.74m0 0v6.005m-1.738 0h3.477"/>
				</svg>
				<p>Coming soon. </p>
				<button>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 9 9">
						<path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M8 1 1 8m7 0L1 1"/>
					</svg>
				</button>
			</div> */}
      <PageTop title="Perks" />
      <div className="content">
        {/* <div className="prev_earn d_flex ai_c">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26">
						<circle cx="12.996" cy="13.022" r="11.996" stroke="#fff" transform="rotate(180 12.996 13.022)"/>
						<circle cx="12.982" cy="13.009" r="9.007" stroke="#fff" transform="rotate(180 12.982 13.009)"/>
						<circle cx="12.989" cy="8.506" r="1.501" stroke="#fff" transform="rotate(180 12.989 8.506)"/>
						<path stroke="#fff" d="M12.728 24.991v-2.383m0-19.225V1M2.578 18.913l2.063-1.191m16.626-9.626 2.064-1.192m-20.753.161L4.64 8.256m16.627 9.626 2.064 1.192M11.23 12.258h1.739m1.739 0h-1.74m0 0v6.005m-1.738 0h3.477"/>
					</svg>
					<p>Coming soon. </p>
					<button>
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 9 9">
							<path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M8 1 1 8m7 0L1 1"/>
						</svg>
					</button>
				</div> */}
        <div className="content_area">
          <div className="block_content perks_row d_flex jc_sb">
            <div className="perks_element">
              <div className="perks_img d_flex ai_c jc_c">
                <img src="assets/img/perks/item_1.svg" alt="perk" />
              </div>
              <p>Get free crypto on Stake.com</p>
              {/* <a href="#">tike free crypto</a> */}
            </div>
            <div className="perks_element">
              <div className="perks_img d_flex ai_c jc_c">
                <img src="assets/img/perks/item_2.svg" alt="perk" />
              </div>
              <p>Get free crypto on Binance.com</p>
              {/* <a href="#">tike free crypto</a> */}
            </div>
            <div className="perks_element">
              <div className="perks_img d_flex ai_c jc_c">
                <img src="assets/img/perks/item_3.svg" alt="perk" />
              </div>
              <p>Get free crypto on Coindeal.com</p>
              {/* <a href="#">tike free crypto</a> */}
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Perks
