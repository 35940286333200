import {
  format,
  startOfMonth,
  startOfYear,
  addMonths,
  lastDayOfMonth,
  add,
} from 'date-fns'

const helper = {
  getFirstDayOfMonth: (date) =>
    format(startOfMonth(date ? new Date(date) : new Date()), 'yyyy-MM-dd'),
  getLastDayOfMonth: (date) =>
    format(lastDayOfMonth(date ? new Date(date) : new Date()), 'yyyy-MM-dd'),
  getFirstDayOfLastMonth: () =>
    format(addMonths(startOfMonth(new Date()), -1), 'yyyy-MM-dd'),
  getToday: () => format(new Date(), 'yyyy-MM-dd'),
  getFirstDayOfYear: () => format(startOfYear(new Date()), 'yyyy-MM-dd'),
  format: (date) => format(new Date(date), 'yyyy-MM-dd'),
  formatDateValue: (date) => format(new Date(date), 'yyyy-MM-dd'),
  formatRequestDate: (date) => format(new Date(date), 'dd-MM-yyyy'),

  getFilterDates: () => {
    const minDate = '2022-07-07'
    let result = []
    let startDate = minDate
    let endDate = null
    while (
      helper.getFirstDayOfMonth(startDate) !== helper.getFirstDayOfMonth()
    ) {
      endDate = helper.getLastDayOfMonth(startDate)
      result.push({
        label: format(new Date(startDate), 'yyyy MMMM'),
        startDate: startDate,
        endDate: endDate,
      })
      startDate = helper.getFirstDayOfMonth(
        add(new Date(endDate), { months: 1 })
      )
    }
    result.push({
      label: format(new Date(startDate), 'yyyy MMMM'),
      startDate: startDate,
      endDate: helper.formatDateValue(new Date()),
    })
    return result
  },
}

export default helper
