import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import ApeItem from '../components/ApeItem';
import PageTop from '../components/PageTop'
import { EarningsAPI, getEstDate } from '../store/adminApi'
import * as Actions from '../store/actions'
import DateHelper from '../utils/DateHelpers'
import { format } from 'date-fns'
// import { getClaimData, claim } from '../store/api'
// import {
//   getClaimData as getChristmasClaimData,
//   claim as claimChristmas,
// } from '../store/apiChristmas'

// const { getClaimData, claim, getChristmasClaimData, claimChristmas } = {
//   getClaimData: () => {},
//   claim: () => {},
//   getChristmasClaimData: () => {},
//   claimChristmas: () => {},
// }

const Earnings = (props) => {
  const {
    address,
    dispatch,
    claimData,
    claimData_christmas: christmasClaimData,
  } = props

  const [earnings, setEarnings] = useState(null)
  const [customStats, setCustomStats] = useState(null)
  const [filterType, setFilterType] = useState(0)

  const [reports, setReports] = useState(null)

  useEffect(() => {
    const filter = DateHelper.getFilterDates()[filterType]
    EarningsAPI.getEarnings({
      startDate: DateHelper.formatRequestDate(filter.startDate),
      endDate: DateHelper.formatRequestDate(filter.endDate),
    })
      .then((res) => {
        if (res && res.data && res.data.success)
          setEarnings({ time: res.data.time, ...res.data.response.data })
      })
      .catch((err) => console.log(err.message))

    EarningsAPI.getCustomStat(filter.startDate)
      .then((res) => {
        if (res && res.data && res.data.length > 0) {
          setCustomStats(res.data[0])
        } else {
          setCustomStats(null)
        }
      })
      .catch((err) => {
        console.log(err.message)
        setCustomStats({})
      })
  }, [filterType])

  useEffect(() => {
    EarningsAPI.getReports({ limit: 10 })
      .then((res) => {
        if (res && res.data) setReports(res.data)
      })
      .catch((err) => console.log(err.message))
  }, [])

  useEffect(() => {
    if (address) dispatch(Actions.getClaimData(address))
  }, [address])

  const { tokens, tokens_christmas } = props

  const claimReward = () => {
    dispatch(Actions.claim('apes', address))
  }

  const claimChristmasReward = () => {
    dispatch(Actions.claim('christmas-apes', address))
  }

  // const lastUpdate = earnings
  //   ? getEstDate(earnings.time).replace(',', ' at').toLowerCase()
  //   : null

  const handleFilterChange = (newFilterType) => {
    setFilterType(newFilterType)
  }

  return (
    <main id="earnings">
      <PageTop title="My Earnings" />

      <div className="content">
        <div
          className="monkey_earn content_area"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div className="block_content">
            <div className="title d_flex">
              <img src="/assets/img/earnings/coin.svg" alt="coin" />
              <p>
                You own <span>{tokens.length} Gambling Apes</span>,{' '}
                <span>{tokens_christmas.length} Christmas Apes</span> and will
                earn a total of{' '}
                <span>
                  {((tokens.length + tokens_christmas.length) * 0.009).toFixed(
                    4
                  )}
                  % of Casino Profits
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="earning-dashboards filter">
          <div
            className="content_area updated_date"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <select
              value={filterType}
              onChange={(e) => handleFilterChange(e.target.value)}
            >
              {DateHelper.getFilterDates().map((filter, i) => (
                <option key={i} value={i}>
                  {filter.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="earning-dashboards">
          <EarningCard
            title="Gross Gaming Revenue"
            value={earnings && r_curr(earnings?.ggr)}
            icon={EarningIcon1}
          />
          <EarningCard
            title="Deposits"
            value={earnings && r_curr(earnings?.deposits)}
            icon={EarningIcon2}
          />
          <EarningCard
            title="Withdrawals"
            value={earnings && r_curr(earnings.withdrawals)}
            icon={EarningIcon3}
          />
          <EarningCard
            title="Amount Wagered"
            value={earnings && r_curr(earnings.wager)}
            icon={EarningIcon4}
          />
          <EarningCard
            title="Wins"
            value={earnings && r_curr(earnings.wins)}
          />
          <EarningCard
            title="Users"
            value={earnings && r_count(earnings.users)}
            icon={EarningIcon5}
          />
        </div>

        <div className="earning-dashboards">
          {/* <div
            className="content_area updated_date"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <p>Last updated: {lastUpdate} EST</p>
          </div> */}
          <EarningCard
            title="Net Gaming Revenue"
            value={
              earnings &&
              r_curr(customStats?.value ? (customStats.value / 7777) * 100 : 0)
            }
            icon={EarningIcon5}
          />
        </div>

        <ProfitCard
          title="GAMBLING APES Profits"
          type="g"
          claim={() => claimReward()}
          lifetime={
            claimData?.totalAmount
              ? Number(claimData.totalAmount).toFixed(4) + ' ETH'
              : 0
          }
          claimData={
            claimData?.total ? Number(claimData.total).toFixed(4) + ' ETH' : 0
          }
        />

        <ProfitCard
          title="🎄 christmas Gambling apes PROFIT"
          type="c"
          claim={() => claimChristmasReward()}
          lifetime={
            christmasClaimData?.totalAmount
              ? Number(christmasClaimData.totalAmount).toFixed(4) + ' ETH'
              : 0
          }
          claimData={
            christmasClaimData?.total
              ? Number(christmasClaimData.total).toFixed(4) + ' ETH'
              : 0
          }
        />

        <div
          className="monkey_earn content_area monthlyReports"
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div className="block_title">Monthly Reports</div>
          <div className="block_content">
            {reports &&
              reports.map((report) => (
                <div key={report.id}>
                  <span>{report.name}</span>{' '}
                  <a
                    href={report.link}
                    target="_blank"
                    className="btn"
                    rel="noreferrer"
                  >
                    Open
                  </a>
                </div>
              ))}
          </div>
        </div>
      </div>
    </main>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

const mapsStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    tokens: state.app.tokens,
    tokens_christmas: state.app.tokens_christmas,
    address: state.app.address,
    claimData: state.app.claimData,
    claimData_christmas: state.app.claimData_christmas,
  }
}

export default withRouter(
  connect(mapsStateToProps, mapDispatchToProps)(Earnings)
)

const currencyFormater = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
})

const countFormater = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

// render formated currency
const r_curr = (v) => (v ? currencyFormater.format(v / 100) : '---')
// render formated count
const r_count = (v) => (v ? countFormater.format(v) : '---')

const ProfitCard = ({ title, type, lifetime, claimData, claim }) => {
  return (
    <div
      className="monkey_earn content_area"
      data-aos="fade-up"
      data-aos-once="true"
    >
      <div className="block_title">{title}</div>
      <div className="block_content">
        <div className="columns_row d_flex jc_sb">
          <div className="monkey_column">
            <div className="block_title">
              Lifetime <img src="/assets/img/earnings/coin.svg" alt="coin" />
            </div>
            <div className="block_content">
              <p>{lifetime}</p>
              <img
                src={`/assets/img/earnings/earning_${type}_ape_1.png`}
                alt="ape"
              />
            </div>
          </div>

          <div className="monkey_column">
            <div className="block_title">
              Available for claim{' '}
              <img src="/assets/img/earnings/coin.svg" alt="coin" />
            </div>
            <div className="block_content">
              <div>
                <p>{claimData}</p>
                {!!claimData && (
                  <button className="btn" onClick={claim}>
                    Claim
                  </button>
                )}
              </div>
              <img
                src={`/assets/img/earnings/earning_${type}_ape_2.png`}
                alt="ape"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const EarningCard = ({ title, value, icon }) => (
  <div className="content_area" data-aos="fade-up" data-aos-once="true">
    <div className="block_content">
      <p>{title}</p>
      <b>{value}</b>
      {icon}
    </div>
  </div>
)

const EarningIcon1 = (
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1431 5.89218H7.29299V8.04696H10.7076V10.2127H6.85742"
      stroke="white"
    />
    <path d="M9.03516 5.89213V4.42871" stroke="white" />
    <path d="M9.03516 11.6761V10.2127" stroke="white" />
    <path d="M3.85714 3.85714V13H14.1429V3.85714M1 5L9 1L17 5" stroke="white" />
  </svg>
)
const EarningIcon2 = (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.2135 4.93392L6.2135 8.33269L7.99678 8.33269L7.99678 5.31837L9.78916 5.31837L9.78916 8.71719"
      stroke="white"
    />
    <path d="M6.21306 6.79469L5.00195 6.79469" stroke="white" />
    <path d="M11.0002 6.79469L9.78906 6.79469" stroke="white" />
    <path
      d="M11 1.00001L13 1.00001L13 3.13334M11 1.00001L11 3.13334L13 3.13334M11 1.00001L5 1.00001M13 3.13334L13 10.6L3 10.6L3 3.13334M5 1.00001L3 1.00001L3 3.13334M5 1.00001L5 3.13334L3 3.13334"
      stroke="white"
    />
    <path d="M13 8.46666H15V10.6H1V8.46666H3" stroke="white" />
    <path d="M11 13.8667L8 17M8 17L8 12.2M8 17L5 13.8667" stroke="white" />
  </svg>
)
const EarningIcon3 = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.666 4.21344L7.2672 4.21344L7.2672 5.99672L10.2815 5.99672L10.2815 7.7891L6.88269 7.7891"
      stroke="white"
    />
    <path d="M8.80518 4.21306L8.80518 3.00195" stroke="white" />
    <path d="M8.80518 9.00017L8.80518 7.78906" stroke="white" />
    <path
      d="M17 9L17 11L14.8667 11M17 9L14.8667 9L14.8667 11M17 9L17 3M14.8667 11L14 11M17 3L17 1L14.8667 1M17 3L14.8667 3L14.8667 1M14.8667 1L1 0.999999L1 11L12 11M1 3L3.30029 3L3.30029 0.999999M1 8.69995L3.30029 8.69995L3.30029 11"
      stroke="white"
    />
    <path
      d="M6 14.4668L6 11M6 14.4668L14.1333 14.4668L14.1334 7L12 7L12 11L6 11M6 14.4668L6 17L13 17L13 14.5M6 11.4668L6 11"
      stroke="white"
    />
  </svg>
)
const EarningIcon4 = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="9" cy="8.99107" rx="8" ry="7.99107" stroke="white" />
    <ellipse
      cx="9.00889"
      cy="8.99999"
      rx="6.0067"
      ry="5.99999"
      stroke="white"
    />
    <path d="M9.17859 1.01782V2.60533" stroke="white" />
    <path d="M9.17859 15.4126V17.0001" stroke="white" />
    <path d="M15.9479 5.06689L14.5715 5.86065" stroke="white" />
    <path d="M3.48376 12.2732L2.1074 13.0669" stroke="white" />
    <path d="M3.48328 5.75366L2.10691 4.95991" stroke="white" />
    <path d="M15.9918 12.9937L14.6155 12.1999" stroke="white" />
  </svg>
)
const EarningIcon5 = (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 13V17M8 17H15V14C15 11.2386 12.7614 9 10 9H6C3.23858 9 1 11.2386 1 14V17H8Z"
      stroke="white"
    />
    <path
      d="M8 13V17M8 17H15V14C15 11.2386 12.7614 9 10 9H6C3.23858 9 1 11.2386 1 14V17H8Z"
      stroke="white"
    />
    <path d="M13 14H10" stroke="white" />
    <path d="M13 14H10" stroke="white" />
    <ellipse
      cx="8.00446"
      cy="4.99999"
      rx="4.00446"
      ry="3.99999"
      stroke="white"
    />
    <ellipse
      cx="8.00446"
      cy="4.99999"
      rx="4.00446"
      ry="3.99999"
      stroke="white"
    />
    <path d="M11.5 9.5L8 13L4.5 9.5" stroke="white" />
    <path d="M11.5 9.5L8 13L4.5 9.5" stroke="white" />
  </svg>
)
