const PageTop = ({title, subtitle}) => {

	return (
		<div className="main_header" data-aos="fade-in" data-aos-once="true">
			<h1>{title}</h1>
			{subtitle && <p>{subtitle}</p>}
		</div>
	);
}

export default PageTop;