import { useState } from 'react';
import { format } from 'date-fns';
const Dialog = ({ activator, title, info, date, location, url }) => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<>
			<span onClick={() => {setIsOpen(true)}} className="dialog-activator">{activator}</span>
			{isOpen && <div className="dialog-wrapper">
				<div className="dialog">
					<button className="dialog-close-btn" onClick={() => setIsOpen(false)}>
						<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M14 1.00122L1.00122 14" stroke="#424247" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M14 13.9988L1.00122 0.999998" stroke="#424247" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
						</svg>
					</button>
					<div className="dialog-top">
						<div className="dialog-top__title">{title}</div>
						<div className="dialog-top__date">{format(new Date(date), 'MM/dd hhaaa')} EST</div>

						{location && <div className="dialog-top__location">
							<svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1 4.18321C1 5.94656 4 10 4 10C4 10 7 5.94656 7 4.18321C7 2.41985 5.66187 1 4 1C2.33813 1 1 2.41985 1 4.18321Z" stroke="#424247" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M4 5C4.55229 5 5 4.55228 5 4C5 3.44772 4.55229 3 4 3C3.44771 3 3 3.44772 3 4C3 4.55228 3.44771 5 4 5Z" stroke="#424247" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>
							{location}
						</div>}
						
					</div>

					{url && <a className="btn-default dialog-btn" href={url}>Link to Event</a>}
					
					<div className="dialog-content">{info}</div>
					
				</div>
			</div>}
		</>
	);
}

export default Dialog;