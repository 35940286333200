import React from 'react'
import Sidebar from '../components/Sidebar'
// import BackToTop from '../components/BackToTop';
import Footer from '../components/Footer'
import ToastNotification from '../components/ToastNotification'

const LayoutDefault = ({ children }) => (
  <>
    <img className="page-bg" alt="Back" src="/assets/img/background.png" />
    <div className="wrap d_flex">
      <Sidebar />
      {children}
    </div>
    <Footer />
    {/* <BackToTop /> */}
    <ToastNotification />
  </>
)

export default LayoutDefault
