import { combineReducers } from 'redux'
import * as Actions from './actions'

const initialState = {
  notification: {
    type: '',
    msg: '',
  },
  tokens: [],
  tokens_christmas: [],
  address: null,
  gettingTokens: false,
  tokenMetaData: {},
  gettingTokenMetaData: false,
  provider: null,
  claimData: null,
  claimData_christmas: null,
}

const app = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_NOTIFICATION: {
      return {
        ...state,
        notification: action.data,
      }
    }
    case Actions.CLEAR_NOTIFICATION: {
      return {
        ...state,
        notification: {
          type: '',
          msg: '',
        },
      }
    }
    case Actions.GETTING_USER_TOKENS: {
      return {
        ...state,
        gettingTokens: true,
      }
    }
    case Actions.GET_USER_TOKENS: {
      return {
        ...state,
        tokens: action.data.tokens,
        tokens_christmas: action.data.tokens_christmas,
        address: action.data.address,
        gettingTokens: false,
      }
    }
    case Actions.GETTING_TOKEN_METADATA: {
      return {
        ...state,
        gettingTokenMetaData: true,
      }
    }
    case Actions.GET_TOKEN_METADATA: {
      const { tokenMetaData } = state
      return {
        ...state,
        tokenMetaData: {
          ...tokenMetaData,
          [action.data.tokenId]: action.data.metaData,
        },
        gettingTokenMetaData: false,
      }
    }
    case Actions.API_CONNECTOR_INIT: {
      return {
        ...state,
        provider: action.data,
      }
    }
    case Actions.GET_CLAIM_DATA: {
      return {
        ...state,
        claimData:
          action.data.claimData === undefined
            ? state.claimData
            : action.data.claimData,
        claimData_christmas:
          action.data.claimData_christmas === undefined
            ? state.claimData_christmas
            : action.data.claimData_christmas,
      }
    }
    case Actions.API_ERROR: {
      return {
        ...state,
        // gettingTokens: false,
        notification: {
          type: 'error',
          msg: action.data.message,
        },
      }
    }
    default: {
      return state
    }
  }
}

const createReducer = (asyncReducers) =>
  combineReducers({
    app,
    ...asyncReducers,
  })

export default createReducer
