import { TWITTER_LINK, OPENSEA_LINK, DISCORD_LINK, INSTAGRAM_LINK } from '../Constants';

const Footer = () => {

	return <footer className="footer">
		<div className="footer__inner">
			<div className="footer__title">Gambling Apes Max Bet Club</div>
			<a href="https://looksrare.org/collections/0x90cA8a3eb2574F937F514749ce619fDCCa187d45" target="_blank" rel="noreferrer">
				<img src="/assets/img/home/looksrare.svg" alt="looksrare" />
			</a>
			<a href={OPENSEA_LINK} target="_blank" rel="noreferrer">
				<img src="/assets/img/home/opensea.svg" alt="OpenSea" />
			</a>
			<a href={DISCORD_LINK} target="_blank" rel="noreferrer">
				<img src="/assets/img/home/discord.svg" alt="Discord" />
			</a>
			<a href={TWITTER_LINK} target="_blank" rel="noreferrer">
				<img src="/assets/img/home/twitter.svg" alt="Twitter" />
			</a>
			<a href={INSTAGRAM_LINK} target="_blank" rel="noreferrer">
				<img src="/assets/img/home/instagram.svg" alt="Instagram" />
			</a>
		</div>
	</footer>
}

export default Footer;