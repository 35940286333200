import Web3 from 'web3'
// import axios from 'axios'
import { NFT_ADDRESS, NFT_ADDRESS_CHRISTMAS } from '../Constants'
import NFT_ABI from '../abi/GamblingApes.json'

const APES_ADDRESS = '0x59822e3206b793cF2d305bF2F7A0A89fFD6D9fA5'
const CHRISTMAS_APES_ADDRESS = '0x6369232305E3f95B1d15C39AA495165280E7e22E'

export const TYPE_APES = 'apes'
export const TYPE_C_APES = 'christmas-apes'

class API_CONNECTOR {
  constructor(provider, type) {
    const web3 = new Web3(provider)
    const web3_ethereum = new Web3(window.ethereum)
    this.web3 = web3

    let nftAddress, claimConstractAddress
    this.type = type
    if (type === TYPE_APES) {
      claimConstractAddress = APES_ADDRESS
      nftAddress = NFT_ADDRESS
    }
    if (type === TYPE_C_APES) {
      claimConstractAddress = CHRISTMAS_APES_ADDRESS
      nftAddress = NFT_ADDRESS_CHRISTMAS
    }

    const nftContract = new web3.eth.Contract(NFT_ABI, nftAddress)
    const claimContract = new web3_ethereum.eth.Contract(
      [
        {
          inputs: [
            { internalType: 'uint256[]', name: 'months', type: 'uint256[]' },
            { internalType: 'uint256', name: 'amount', type: 'uint256' },
            { internalType: 'bytes', name: 'signature', type: 'bytes' },
          ],
          name: 'claim',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      claimConstractAddress
    )
    this.nftContract = nftContract
    this.claimContract = claimContract
  }

  getClaimData = async (a) => {
    const web3 = this.web3
    const url =
      this.type === TYPE_APES
        ? 'https://gambling-apes-claiming.herokuapp.com/api/claim/ga?address='
        : 'https://gambling-apes-claiming.herokuapp.com/api/claim/cga?address='
    const response = await fetch(url + a, {
      method: 'GET',
      redirect: 'follow',
    })
    if (!response.ok) {
      const errorMessage = (await response.text()) ?? response.statusText
      return alert(errorMessage)
    }
    /**
     *  `signature`: Signature to send to the contract when claiming                     NOT ALWAYS RETURNED
     *  `address`: Address that only it can claim the ETH                                NOT ALWAYS RETURNED
     *  `months`: Months data to send to the contract alongside `signature` and `total`  NOT ALWAYS RETURNED
     *  `total`: Total ETH **waiting to be claimed** by the `address`                    NOT ALWAYS RETURNED
     *  `totalAmount`: Total amount of ETH that **has** been claimed by the user         ALWAYS RETURNED
     */
    const { signature, total, address, months, totalAmount } =
      await response.json()
    // const eth_totalAmount = utils.formatEther(totalAmount)

    const eth_totalAmount = web3.utils.fromWei(totalAmount, 'ether')
    console.log(eth_totalAmount)
    if (!signature) return { totalAmount, eth_totalAmount }
    else {
      const eth_total = web3.utils.fromWei(total, 'ether')
      return {
        signature,
        total,
        address,
        months,
        totalAmount,
        eth_totalAmount,
        eth_total,
      }
    }
  }

  claim = async (a) => {
    const claimContract = this.claimContract
    const { signature, total, months, totalAmount, address } =
      await this.getClaimData(a)
    if (!signature) {
      throw Error("You don't have a pending claim!")
    }
    const tx = claimContract.methods.claim(months, total, signature)

    // CHECK WHO SHOULD BE IN FROM FIELD
    const params = { from: a }
    const gasEstimation = await tx.estimateGas(params).catch((e) => e)
    if (typeof gasEstimation !== 'number') {
      debugger
      const revertReason = gasEstimation?.message?.match(
        /execution reverted: ([a-zA-Z ]+)/
      )
      const message =
        revertReason?.at(1) ?? gasEstimation?.message ?? gasEstimation
      alert(message)
      return
    }
    const gas = parseInt(gasEstimation * 1.3)
    console.log('Send params: ', { ...params, gas })
    let response = await tx.send({ ...params, gas })
    debugger
    return response
  }
  getUserTokens = async (addr) => {
    try {
      const nftContract = this.nftContract
      if (this.type === TYPE_C_APES) {
        // return await this.getUserChristmasTokens(
        //   '0x4509A6dD84e88FD0d894726abb036d98e411c966'
        // )
        return await this.getUserChristmasTokens(addr)
      }
      // const result = await nftContract.methods
      //   .walletOfOwner('0x4509A6dD84e88FD0d894726abb036d98e411c966')
      //   .call()
      const result = await nftContract.methods.walletOfOwner(addr).call()
      return result
    } catch (e) {
      console.log(e)
      return []
    }
  }
  getUserChristmasTokens = async (addr) => {
    try {
      const nftContract = this.nftContract
      const possibleOwnedTokens = (
        await nftContract.getPastEvents('Transfer', {
          fromBlock: 0,
          filter: {
            to: addr,
          },
        })
      ).map(({ returnValues }) => returnValues.tokenId)

      let result = []
      for (let i = 0; i < possibleOwnedTokens.length; i++) {
        const tokenId = possibleOwnedTokens[i]
        const owner = await nftContract.methods.ownerOf(tokenId).call()
        if (owner === addr) result.push(tokenId)
      }
      return result
    } catch (e) {
      console.log(e)
      throw e
    }
  }
}

export const initAPIConnector = (provider) => {
  const APES = new API_CONNECTOR(provider, TYPE_APES)
  const XMAS_APES = new API_CONNECTOR(provider, TYPE_C_APES)
  return { APES, XMAS_APES }
}
