import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import { useWeb3React } from '@web3-react/core'
import { web3Modal } from '../utils/Connectors'
// import { useEagerConnect, useInactiveListener } from '../utils/Hooks'
// import { getWalletErrorMessage } from '../utils/Helpers'
import * as Actions from '../store/actions'
import Web3 from 'web3'
import { SidebarWrapper, TopPanel, Menu } from './sidebar/index'

const Sidebar = (props) => {
  const { dispatch, tokens, address } = props
  const [account, setAccount] = useState(address)
  // useEffect(() => {
  //   if (error) {
  //     dispatch(
  //       Actions.setNotificationAction(getWalletErrorMessage(error), 'error')
  //     )
  //   }
  // }, [error])

  useEffect(() => {
    if (account !== address) {
      dispatch(Actions.getUserTokensAction(account))
    }
  }, [account, dispatch, address])

  const logOut = () => {
    web3Modal.setCachedProvider(null)
    setAccount(null)
  }
  const onConnect = async () => {
    try {
      const provider = await web3Modal.connect()
      await provider.enable()
      const web3 = new Web3(provider)
      const accounts = await web3.eth.getAccounts()
      const address = accounts[0]
      dispatch(Actions.initAPIConnector(provider))
      setAccount(address)
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    if (web3Modal.cachedProvider) onConnect()
  }, [])

  return (
    <SidebarWrapper>
      <TopPanel account={account} connect={onConnect} logOut={logOut} />
      <Menu account={account} />
    </SidebarWrapper>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

const mapsStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    tokens: state.app.tokens,
    address: state.app.address,
  }
}

export default withRouter(
  connect(mapsStateToProps, mapDispatchToProps)(Sidebar)
)
