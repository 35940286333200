import { useState, useEffect } from "react";
import CustomSelect from "../components/CustomSelect";
import PageTop from "../components/PageTop";
import { CompetitionsAPI } from "../store/adminApi";
import { format } from "date-fns";
import Dialog from "../components/Dialog";

function Competitions() {
	const [categories, setCategories] = useState(null)
	// Load categories once and make first category active
	useEffect(() => {
		CompetitionsAPI.getCategories().then(res => {
			if (res && res.data) {
				let data = res.data;
				setCategories(data);
			}
		})
	}, [])

	const getUpcomingCompetitions = (p) => CompetitionsAPI.getUpcomingCompetitions(p)
	const getPassedCompetitions = (p) => CompetitionsAPI.getPassedCompetitions(p)
	return (
		<main id="competitions">
			{/*<div className="prev_earn d_flex ai_c">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26">
					<circle cx="12.996" cy="13.022" r="11.996" stroke="#fff" transform="rotate(180 12.996 13.022)"/>
					<circle cx="12.982" cy="13.009" r="9.007" stroke="#fff" transform="rotate(180 12.982 13.009)"/>
					<circle cx="12.989" cy="8.506" r="1.501" stroke="#fff" transform="rotate(180 12.989 8.506)"/>
					<path stroke="#fff" d="M12.728 24.991v-2.383m0-19.225V1M2.578 18.913l2.063-1.191m16.626-9.626 2.064-1.192m-20.753.161L4.64 8.256m16.627 9.626 2.064 1.192M11.23 12.258h1.739m1.739 0h-1.74m0 0v6.005m-1.738 0h3.477"/>
				</svg>
				<p>This is a preview of the Gambling Apes High Roller Club. All NFT owners will receive access to this section of the website after mint</p>
				<button>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 9 9">
						<path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M8 1 1 8m7 0L1 1"/>
					</svg>
				</button>
			</div>*/}
			<PageTop title="Competitions" />
			{categories && <div className="content">
				<div className="upcoming content_area" data-aos="fade-up" data-aos-once="true">
					<CompetitionsTable categories={categories} tableFetch={getUpcomingCompetitions} tableTitle="Upcoming Competitions" />
				</div>
				<div className="upcoming content_area" data-aos="fade-up" data-aos-once="true">
					<CompetitionsTable categories={categories} tableFetch={getPassedCompetitions} tableTitle="PAST COMPETITIONS " />
				</div>
			</div>}
		</main>
	);
}

export default Competitions;


const CompetitionsTable = ({ categories, tableFetch, tableTitle }) => {
	const [competitions, setCompetitions] = useState(null)
	const [activeCategory, setActiveCategory] = useState('All')

	useEffect(() => {
		let activeCategoryId = activeCategory && activeCategory !== 'All' ? categories.find(c => c.name === activeCategory) : null
		if (activeCategoryId) activeCategoryId = activeCategoryId.id; 
		tableFetch({ category: activeCategoryId }).then(res => {
			if (res && res.data) 
				setCompetitions(res.data)
		})
	}, [activeCategory, categories, tableFetch])

	return <>
		<div className="block_title d_flex jc_sb ai_c">
			<span>
				{tableTitle}
			</span>
			<CustomSelect
				data={['All', ...categories.map(c => c.name)]}
				title={"All"}
				defaultSelect={activeCategory}
				action={(v) => setActiveCategory(v)}
			/>
		</div>
		<div className="block_content">
			<div className="upcoming_table comp_table">
				<div className="comp_table_header d_flex jc_sb">
					<div className="date">Date</div>
					<div className="time">Time (EST)</div>
					<div className="category">Category</div>
					<div className="competition_name">Competition Name</div>
					<div className="info">Info</div>
					<div className="prize_pool">Prize Pool</div>
					<div className="winner">Winner</div>
				</div>
				<div className="comp_table_content">
					<div className="content_scroll_table">
					{
						competitions && competitions.map((competition) => 
							<div className="ctc_row" key={competition.id}>
								<div className="date">{format(new Date(competition.date), 'd MMM yyyy')}</div>
								<div className="time">{format(new Date(competition.date), 'HH:mm')}</div>
								<div className="category">{competition.competitionCategory?.name || '---'}</div>
								<div className="competition_name">{competition.name}</div>
								<div className="info">
									{competition.description ? <Dialog
										title={competition.name}
										info={competition.description}
										date={competition.date}
										location={competition.location}
										url={competition.url}
										activator="Click Here">
									</Dialog> : 'Not available'}
								</div>
								<div className="prize_pool">${competition.prize}</div>
								<div className="winner">{competition.winners ? <Winners winners={competition.winners} /> : 'TBA'}</div>
							</div>
						)
					}
					</div>
				</div>
			</div>
		</div>
	</>
}

const Winners = ({ winners }) => {
	const [showAll, setShowAll] = useState(false)
	return (
		<div
			className="winners-list"
			onMouseEnter={() => setShowAll(true)}
			onMouseLeave={() => setShowAll(false)}>
			<span>
				<b>{1}st </b>
				<a href={winners[0].url}>{winners[0].name}</a>
			</span> 		
			{showAll && <ul>
				{winners.map((w, i) =>
					<li className="winners-list__item">
						<b>{i + 1}st </b>
						<a href={w.url}>{w.name}</a>
					</li>
				)}
			</ul>}
		</div>
	)
}