import userAva from '../../assets/img/nav/usr_ava.svg'
import userIcon from '../../assets/img/nav/user_icon.svg'

const TopPanel = ({ account, connect, logOut }) => {
  return (
    <div className="user_main_info">
      <div className="card-nav ai_c">
        {account ? (
          <UserCard account={account} />
        ) : (
          <>
            <button className="btn-connect-wallet" onClick={connect}>
              CONNECT WALLET
            </button>
          </>
        )}
        <div className="usr_status">
          <img src={userIcon} alt="user-icon" />
          {account ? <OnlineStatus logOut={logOut} /> : <OfflineStatus />}
        </div>
      </div>
    </div>
  )
}

export default TopPanel

const UserCard = ({ account }) => {
  return (
    <div className="usr-block">
      <div className="usr_ava">
        <img src={userAva} alt="avatar" />
      </div>
      <div className="usr_description">
        <p>Ape Holder</p>
        <p className="usr_id">
          {`${account.substring(0, 6)}...${account.substring(
            account.length - 4
          )}`}
        </p>
      </div>
    </div>
  )
}

const OnlineStatus = ({ logOut }) => {
  return (
    <>
      <img src="/assets/img/nav/online.svg" alt="online" />
      <p>Connected</p>
      <button className="log-out-btn" onClick={logOut}>
        log out
      </button>
    </>
  )
}

const OfflineStatus = () => {
  return (
    <>
      <img src="/assets/img/nav/offline.svg" alt="offline" />
      <p>Unconnected</p>
    </>
  )
}
