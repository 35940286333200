// import * as API from './api'
// import * as API_CHRISTMAS from './apiChristmas'
import { initAPIConnector as initAPI, TYPE_APES } from './apiConnector'

export const SET_NOTIFICATION = 'SET_NOTIFICATION'
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION'
export const GETTING_USER_TOKENS = 'GETTING_USER_TOKENS'
export const GET_USER_TOKENS = 'GET_USER_TOKENS'
export const GETTING_TOKEN_METADATA = 'GETTING_TOKEN_METADATA'
export const GET_TOKEN_METADATA = 'GET_TOKEN_METADATA'
export const API_ERROR = 'API_ERROR'
export const API_CONNECTOR_INIT = 'API_CONNECTOR_INIT'
export const GET_CLAIM_DATA = 'GET_CLAIM_DATA'

let API = null
let API_CHRISTMAS = null

export const setNotificationAction = (msg_content, msg_type = 'success') => {
  return (dispatch) => {
    return dispatch({
      type: SET_NOTIFICATION,
      data: {
        type: msg_type,
        msg: msg_content,
      },
    })
  }
}

export const getUserTokensAction = (addr) => {
  return (dispatch) => {
    dispatch({
      type: GETTING_USER_TOKENS,
    })
    if (!addr) {
      dispatch({
        type: GET_USER_TOKENS,
        data: {
          tokens: [],
          tokens_christmas: [],
          address: addr,
        },
      })
      return
    }
    const promises = [
      API.getUserTokens(addr),
      API_CHRISTMAS.getUserTokens(addr),
    ]
    Promise.all(promises)
      .then((results) => {
        return dispatch({
          type: GET_USER_TOKENS,
          data: {
            tokens: results[0],
            tokens_christmas: results[1],
            address: addr,
          },
        })
      })
      .catch((error) => {
        return dispatch({
          type: API_ERROR,
          data: error,
        })
      })
  }
}

export const initAPIConnector = (provider) => {
  return (dispatch) => {
    let api = initAPI(provider)
    API = api.APES
    API_CHRISTMAS = api.XMAS_APES
    dispatch({
      type: API_CONNECTOR_INIT,
      data: provider,
    })
  }
}

export const getTokenMetaDataAction = (tokenId) => {
  return (dispatch) => {
    dispatch({
      type: GETTING_TOKEN_METADATA,
    })
    API.getTokenMetaData(tokenId)
      .then((result) => {
        return dispatch({
          type: GET_TOKEN_METADATA,
          data: {
            tokenId,
            metaData: result,
          },
        })
      })
      .catch((error) => {
        return dispatch({
          type: API_ERROR,
          data: error,
        })
      })
  }
}

export const getClaimData = (address) => {
  return (dispatch) => {
    const promises = [
      API.getClaimData(address),
      API_CHRISTMAS.getClaimData(address),
    ]
    Promise.all(promises)
      .then((results) => {
        const claimData = results[0]
        const claimData_christmas = results[1]
        return dispatch({
          type: GET_CLAIM_DATA,
          data: {
            claimData: {
              totalAmount: claimData.eth_totalAmount,
              total: claimData.eth_total,
            },
            claimData_christmas: {
              totalAmount: claimData_christmas.eth_totalAmount,
              total: claimData_christmas.eth_total,
            },
          },
        })
      })
      .catch((error) => {
        return dispatch({
          type: API_ERROR,
          data: error,
        })
      })
  }
}

export const claim = (type, address) => {
  return (dispatch) => {
    const claimMethod = type === TYPE_APES ? API.claim : API_CHRISTMAS.claim
    const dataKey = type === TYPE_APES ? 'claimData' : 'claimData_christmas'

    claimMethod(address)
      .then((response) => {
        setNotificationAction('Claim succesfully')
        return dispatch({
          type: GET_CLAIM_DATA,
          data: {
            [dataKey]: {
              totalAmount: 0,
              total: 0,
            },
          },
        })
      })
      .catch((err) => {
        return dispatch({
          type: API_ERROR,
          data: err,
        })
      })
  }
}
