import TopPanel from './TopPanel'
import Menu from './Menu'
import { Link } from 'react-router-dom'
import { useWindowDimensions } from '../../utils/Hooks'

const SidebarWrapper = ({ children }) => {
  const { width } = useWindowDimensions()
  return (
    <div className="nav_area">
      <div className="logo">
        <Link to="/">
          <img src="/assets/img/logo.svg" alt="Logo" />
        </Link>
      </div>
      <div className="responsive_area">
        {width > 850 ? (
          children
        ) : (
          <div className="mobile_menu">
            <div className="hamburger hamburger--squeeze js-hamburger">
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
            <div className="mob_menu_content">{children}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export { SidebarWrapper, TopPanel, Menu }
