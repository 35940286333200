// import { useState, useEffect } from "react";
// import { MerchAPI } from "../store/adminApi";
// import { ADMIN_API_URL } from "../Constants";
import PageTop from "../components/PageTop";

function Merch() {
	
	return (
		<main id="merch">
			<PageTop title="Merch" />
                    
			<div className="merch_marker d_flex ai_c">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26">
					<circle cx="12.996" cy="13.023" r="11.996" stroke="#06060F" transform="rotate(180 12.996 13.023)" />
					<circle cx="12.982" cy="13.009" r="9.007" stroke="#06060F" transform="rotate(180 12.982 13.01)" />
					<circle cx="12.989" cy="8.506" r="1.501" stroke="#06060F" transform="rotate(180 12.99 8.506)" />
					<path stroke="#06060F" d="M12.727 24.992v-2.383m0-19.225V1M2.578 18.914l2.064-1.192m16.625-9.626 2.063-1.191m-20.752.16 2.064 1.192m16.625 9.626 2.063 1.191m-12.1-6.815h1.74m1.738 0h-1.739m0 0v6.005m-1.739 0h3.478" />
				</svg>
				<p>Launching soon! 🔥</p>
			</div>
		</main>);
}

// function Merch() {
// 		const [categories, setCategories] = useState(null)
// 		const [merches, setMerches] = useState(null)
// 		const [activeCategory, setActiveCategory] = useState(null);
	
// 		// Load categories once and make first category active
// 		useEffect(() => {
// 			MerchAPI.getCategories().then(res => {
// 				if (res && res.data) {
// 					let data = res.data;
// 					// let promises = [];
// 					// data.forEach(category => promises.push(fetch(ADMIN_API_URL + category.icon.url).then(res => res.text())))
// 					// Promise.all(promises).then(res => {
// 					// 	for (let i = 0; i < data.length; i++) {
// 					// 		data[i].icon = res[i];
// 					// 	}
// 					// })
// 					setCategories(data);
// 					setActiveCategory(data[0].id);
// 				}
// 			})
// 		}, [])
	
// 		// Load merches every time active category changes
// 		useEffect(() => {
// 			if(!activeCategory) return
// 			MerchAPI.getMerches({category: activeCategory}).then(res => {
// 				if (res && res.data) {
// 					setMerches(res.data)
// 				}
// 			})
// 		}, [activeCategory])

//     return (
//         <main id="merch">
// 					{/* <div className="prev_earn d_flex ai_c">
// 						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26">
// 							<circle cx="12.996" cy="13.022" r="11.996" stroke="#fff" transform="rotate(180 12.996 13.022)"/>
// 							<circle cx="12.982" cy="13.009" r="9.007" stroke="#fff" transform="rotate(180 12.982 13.009)"/>
// 							<circle cx="12.989" cy="8.506" r="1.501" stroke="#fff" transform="rotate(180 12.989 8.506)"/>
// 							<path stroke="#fff" d="M12.728 24.991v-2.383m0-19.225V1M2.578 18.913l2.063-1.191m16.626-9.626 2.064-1.192m-20.753.161L4.64 8.256m16.627 9.626 2.064 1.192M11.23 12.258h1.739m1.739 0h-1.74m0 0v6.005m-1.738 0h3.477"/>
// 						</svg>
// 						<p>This is a preview of the Earnings Section. This section will display actual Data after the Casino launches. </p>
// 						<button>
// 							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 9 9">
// 								<path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M8 1 1 8m7 0L1 1"/>
// 							</svg>
// 						</button>
// 					</div> */}
// 				<PageTop title="Merch" />
// 				<div className="merch-banner">
// 					New merch shop is launching later this week! 🔥
// 				</div>
// 				{categories && <div className="merch-tabs" data-aos="fade-up" data-aos-once="true">
// 					<div className="merch-tabs_header">
// 						{categories.map(category => (
// 							<div
// 								className={`merch-tabs_header__item ${activeCategory === category.id ? "active" : ""}`}
// 								onClick={() => setActiveCategory(category.id)}>
// 								{category.name}
// 							</div>)
// 						)}
// 					</div>
// 					<div className="merch-tabs_content">
// 					{
// 						merches && merches.map((merch, idx) => 
// 							<div className="merch_element" key={idx}>
// 									<div className="merch_photo">
// 											<div className="bg_item"></div>
// 											<img src={ADMIN_API_URL + merch.image.url}/>
// 									</div>
// 									<div className="merch_description">
// 										<p className="title">{merch.name}</p>
// 										<p className="price">${merch.price}</p>
// 										{merch.availability ?
// 											<a className="btn-default btn-buy" href="#">Buy now</a>
// 											: merch.info
// 											? <div className="btn btn-buy">{merch.info}</div>
// 											: null
// 										}
// 									</div>
// 							</div>
// 							)
// 					}
// 					</div>
// 				</div>}
                    
//         <div className="merch_marker d_flex ai_c">
// 					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26">
// 						<circle cx="12.996" cy="13.023" r="11.996" stroke="#06060F" transform="rotate(180 12.996 13.023)"/>
// 						<circle cx="12.982" cy="13.009" r="9.007" stroke="#06060F" transform="rotate(180 12.982 13.01)"/>
// 						<circle cx="12.989" cy="8.506" r="1.501" stroke="#06060F" transform="rotate(180 12.99 8.506)"/>
// 						<path stroke="#06060F" d="M12.727 24.992v-2.383m0-19.225V1M2.578 18.914l2.064-1.192m16.625-9.626 2.063-1.191m-20.752.16 2.064 1.192m16.625 9.626 2.063 1.191m-12.1-6.815h1.74m1.738 0h-1.739m0 0v6.005m-1.739 0h3.478"/>
// 					</svg>
// 					<p>You can only buy one product in each category per Wallet Address. This will not reset.</p>
// 					<div className="close">
// 						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 9 9">
// 							<path stroke="#06060F" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M7.999 1.002.999 8m7 0-7-7"/>
// 						</svg>
// 					</div>	
// 				</div>
// 			</main>
//     );
// }

export default Merch;