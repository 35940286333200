import React, { useState, useEffect } from "react";
import { DISCORD_LINK, ADMIN_API_URL } from '../Constants';
import { EventsAPI } from "../store/adminApi";
import { format } from "date-fns";

function Events() {
	const [upcomingEvents, setUpcomingEvents] = useState(null);
	const [passedEvents, setPassedEvents] = useState(null);
	useEffect(() => {
		Promise.all([EventsAPI.getUpcomingEvents(), EventsAPI.getPassedEvents()]).then(res => {
			if (res && res.length === 2) {
				setUpcomingEvents(res[0].data);
				setPassedEvents(res[1].data);
				console.log(res.map(el => el.data))
			}
		}).catch(err => {
			console.log(err)
		})
	}, [])

	return (
		<main id="parties">
			{/* <div className="prev_earn d_flex ai_c">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26">
					<circle cx="12.996" cy="13.022" r="11.996" stroke="#fff" transform="rotate(180 12.996 13.022)"/>
					<circle cx="12.982" cy="13.009" r="9.007" stroke="#fff" transform="rotate(180 12.982 13.009)"/>
					<circle cx="12.989" cy="8.506" r="1.501" stroke="#fff" transform="rotate(180 12.989 8.506)"/>
					<path stroke="#fff" d="M12.728 24.991v-2.383m0-19.225V1M2.578 18.913l2.063-1.191m16.626-9.626 2.064-1.192m-20.753.161L4.64 8.256m16.627 9.626 2.064 1.192M11.23 12.258h1.739m1.739 0h-1.74m0 0v6.005m-1.738 0h3.477"/>
				</svg>
				<p>This is a preview of the Gambling Apes High Roller Club. All NFT owners will receive access to this section of the website after mint</p>
				<button>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 9 9">
						<path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M8 1 1 8m7 0L1 1"/>
					</svg>
				</button>
			</div> */}
			
			<div className="main_header" data-aos="fade-in" data-aos-once="true">
				<h1>Events</h1>
			</div>
			<div className="content">
				<div className="upcoming content_area" data-aos="fade-up" data-aos-once="true">
					<div className="block_title d_flex jc_sb ai_c">
						<span>
							Upcoming Events
						</span>
						{/* <CustomSelect
							data={['All']}
							title={"All"}
							// defaultSelect={activeCategory}
							// action={(v) => setActiveCategory(v)}
						/> */}
					</div>
					<div className="block_content">
						<div className="upcoming_table comp_table">
							<div className="comp_table_content">
								<div className="content_scroll_table">
									<div className="party_area d_flex jc_sb">
										{upcomingEvents && upcomingEvents.map(party => 
											<PartyElement party={party} key={party.id} />
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			
				<div className="parties_info_block d_flex" data-aos="fade-up" data-aos-once="true">
					<div className="pib_image">
						<img src="assets/img/parties/pib.svg" alt="party" />
					</div>
					<div className="pib_description">
						<h3>Want to host your own Gambling Apes Meetup?</h3>
						<p>💰 We will sponsor it with community grands!</p>
						<p>📧 Contact @Sarah1 on discord for more information!</p>
						<a className="btn lets-chat-btn" href="https://discord.gg/gamblingapes">
							Let's chat
							<svg width="21" height="10" viewBox="0 0 21 10" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1 5H20M20 5L16 1M20 5L16 9" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
							</svg>
						</a>
					</div>
				</div>
			
				<div className="upcoming content_area" data-aos="fade-up" data-aos-once="true">
					<div className="block_title">
						Past Events
					</div>
					<div className="block_content">
						<div className="upcoming_table comp_table">
							<div className="comp_table_content">
								<div className="content_scroll_table">
									<div className="party_area d_flex jc_sb">
										{passedEvents && passedEvents.map(party => 
											<PartyElement party={party} isPassed={true} key={party.id} />
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
}

export default Events;

const PartyElement = ({party, isPassed}) => {
	return <div className="party_element">
		<div className="party_image">
			<img src={ADMIN_API_URL + party?.image?.url} alt={party.name} />
		</div>
		<div className="party_description d_flex jc_sb">
			<div className="pd_left">
				<p className="party_title">{party.name}</p>
				<p className="party_date">
					<span>{format(new Date(party.date), 'd MMM yyyy')}</span>
					{party?.location && <> 
						<span className="delimiter">|</span>
						<svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1 4.18321C1 5.94656 4 10 4 10C4 10 7 5.94656 7 4.18321C7 2.41985 5.66187 1 4 1C2.33813 1 1 2.41985 1 4.18321Z" stroke="#424247" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M4 5C4.55229 5 5 4.55228 5 4C5 3.44772 4.55229 3 4 3C3.44771 3 3 3.44772 3 4C3 4.55228 3.44771 5 4 5Z" stroke="#424247" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
						<span className="location">{party?.location}</span>
					</>}
				</p>
			</div>
			<div className="pd_right">
				{!isPassed && <a href={DISCORD_LINK} target="_blank" rel="noreferrer">RSVP NOW</a>}
			</div>
		</div>
	</div>
}