import Axios from 'axios'
import { ADMIN_API_URL } from '../Constants'
import DateHelper from '../utils/DateHelpers'

const axios = Axios.create({
  baseURL: ADMIN_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const EventsAPI = {
  getUpcomingEvents(p) {
    const params = new URLSearchParams({
      _limit: p?.limit || 10,
      date_gte: new Date().toISOString(),
    })
    return axios.get(`/events?${params.toString()}`)
  },
  getPassedEvents(p) {
    const params = new URLSearchParams({
      _limit: p?.limit || 10,
      date_lte: new Date().toISOString(),
    })
    return axios.get(`/events?${params.toString()}`)
  },
}

export const MerchAPI = {
  getCategories(p) {
    const params = new URLSearchParams({ _limit: p?.limit || -1 })
    return axios.get(`/merch-categories?${params.toString()}`)
  },
  getMerches(p) {
    if (!p.category) throw new Error('Category required')
    const params = new URLSearchParams({
      _limit: p?.limit || 10,
      merchCategory_in: p.category,
    })
    return axios.get(`/merches?${params.toString()}`)
  },
}

export const CompetitionsAPI = {
  getCategories() {
    const params = new URLSearchParams({ _limit: -1 })
    return axios.get(`/competition-categories?${params.toString()}`)
  },
  getUpcomingCompetitions(p) {
    const params = new URLSearchParams({
      _limit: p?.limit || 10,
      date_gte: new Date().toISOString(),
    })
    if (p.category) params.append('competitionCategory_in', p.category)
    return axios.get(`/competitions?${params.toString()}`)
  },
  getPassedCompetitions(p) {
    const params = new URLSearchParams({
      _limit: p?.limit || 10,
      date_lte: new Date().toISOString(),
    })
    if (p.category) params.append('competitionCategory_in', p.category)
    return axios.get(`/competitions?${params.toString()}`)
  },
}

export const EarningsAPI = {
  getEarnings({ startDate, endDate }) {
    return axios.post(
      'https://gamblingapes.com/api/stats/global',
      {
        token: 'Y3W7MCWm0jk85Mx07FIkPiLoAoEyhABz',
      },
      { params: { startDate, endDate } }
    )
  },
  getCustomStat(date) {
    return axios.get(
      `/net-gaming-revenues?date=${DateHelper.getFirstDayOfMonth(date)}`
    )
  },
  getReports({ limit }) {
    const params = new URLSearchParams({
      _limit: limit || 10,
      _sort: 'createdAt:desc',
    })
    return axios.get(`/earning-reports?${params.toString()}`)
  },
}

export const getEstDate = (date) => {
  let d = new Date(date)
  return d.toLocaleString('en-US', { timeZone: 'America/New_York' })
}
