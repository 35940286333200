import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
// import { UseWalletProvider } from 'use-wallet';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';

import store from './store';

import ScrollToTop from './utils/ScrollToTop';

import App from './App';

import "../src/assets/scss/style.scss";

const history = createBrowserHistory();

const getLibrary = (provider) => {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

ReactDOM.render(
    <Provider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Router history={history}>
          <ScrollToTop><App /></ScrollToTop>
        </Router>
      </Web3ReactProvider>
    </Provider>,
  document.getElementById('root')
);
