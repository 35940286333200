import React, {useState} from "react";
import {svgArrow} from "./svgIcons";

const CustomSelect = (props) => {
    const {data = [], action, title, defaultSelect = ''} = props;
    const [openSelect, setOpenSelect] = useState(false);
    const [activeSelect, setActiveSelect] = useState(defaultSelect);
    const handleClick = (item) => {
        setOpenSelect(false);
        setActiveSelect(item);
        action && action(item);
    };
    return <div className={`custom-select ${openSelect ? 'show' : ''}`}>
        <div className="custom-select__btn"
             onClick={() => setOpenSelect(!openSelect)}>{activeSelect || title}{svgArrow}</div>
        <ul className="custom-select__select-wrap">
            {
                data.map((item, i) => (
                    <li className={`select ${activeSelect === item ? 'active' : ''}`} onClick={() => handleClick(item)}
                        key={i}>{item}</li>))
            }
        </ul>
    </div>
};

export default CustomSelect;