import { InjectedConnector } from '@web3-react/injected-connector'
import { NETWORK_CHAIN_ID } from '../Constants'
import Web3Modal from 'web3modal'
import CoinbaseWalletSDK from '@coinbase/wallet-sdk'
import WalletConnectProvider from '@walletconnect/web3-provider'
import Torus from '@toruslabs/torus-embed'

export const injected = new InjectedConnector({
  supportedChainIds: [NETWORK_CHAIN_ID],
})

export const coinbasewallet = {
  package: CoinbaseWalletSDK, // Required
  options: {
    appName: 'My Awesome App', // Required
    infuraId: 'INFURA_ID', // Required
    chainId: NETWORK_CHAIN_ID, // Optional. It defaults to 1 if not provided
  },
}
export const walletconnect = {
  package: WalletConnectProvider, // required
  options: {
    infuraId: 'INFURA_ID', // required
  },
}

export const torus = {
  package: Torus, // required
}

export const web3Modal = new Web3Modal({
  network: 'mainnet', // optional
  cacheProvider: true, // optional
  providerOptions: {
    coinbasewallet,
    walletconnect,
    torus,
    injected: {
      package: injected,
    },
  }, // required
})
