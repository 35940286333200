import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import * as Actions from '../store/actions';
import placeholder from '../assets/img/placeholder.png';

const ApeItem = (props) => {
	const { tokenId, dispatch, tokenMetaData, collectionToken } = props;
	const [imgSrc, setImgSrc] = useState(placeholder);
	const [tokenUrl, setTokenUrl] = useState();
	useEffect(() => {
		const tokenBaseUrl = 'https://opensea.io/assets'

		if (parseInt(tokenId) > 0) {
			// if (tokenMetaData && tokenMetaData[tokenId]) {
			// 	setImgSrc(tokenMetaData[tokenId].image);
			// } else {
			// 	dispatch(Actions.getTokenMetaDataAction(tokenId));
			// }
			//setImgSrc(`https://gateway.pinata.cloud/ipfs/QmWjGMMQVRfrUutLkSZztQByEGwNF2VgeHQk6KmhTqemNb/${tokenId}.jpeg`);
			// setImgSrc(`https://looksrare.org/_next/image?url=https%3A%2F%2Fstatic.looksrare.org%2F${collectionToken}%2F${tokenId}&w=1080&q=75`);
			// setTokenUrl(`https://looksrare.org/collections/0x90cA8a3eb2574F937F514749ce619fDCCa187d45/${tokenId}`);
			
			// setImgSrc(`https://opensea.io/assets/0x90cA8a3eb2574F937F514749ce619fDCCa187d45/${tokenId}&w=1080&q=75`);
			setTokenUrl(`${tokenBaseUrl}/${collectionToken}/${tokenId}`);
		}
	}, [tokenId, tokenMetaData, collectionToken]);

	return (
		<>
		{
			tokenUrl? (
				<a href={tokenUrl} target="blank" alt="ape">
					<img src={imgSrc} />
				</a>
			) : (
				<button>
					<img src={imgSrc} alt="ape"/>
				</button>
			)
		}
		</>
		
	);
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    };
}

const mapsStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        tokenMetaData: state.app.tokenMetaData
    }
}

export default withRouter(connect(
    mapsStateToProps, mapDispatchToProps
)(ApeItem));