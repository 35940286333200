import { NavLink } from 'react-router-dom'

const Menu = ({ account }) => {
  return (
    <div className="main_menu">
      <nav>
        <ul>
          <li>
            <NavLink
              exact
              to="/"
              className="d_flex ai_c"
              activeClassName="active"
            >
              <svg
                width="18"
                height="21"
                viewBox="0 0 18 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="6.46032" cy="6.79708" r="0.95202" stroke="white" />
                <circle cx="3.76696" cy="6.79708" r="0.95202" stroke="white" />
                <path d="M10.7746 10.7113H7.57593" stroke="white" />
                <path
                  d="M15.0001 7.72727L16.591 8.40908L16.591 10.1153L9.091 14.9446L1.5 10.1153L1.5002 8.50001L3.18191 7.72727"
                  stroke="white"
                />
                <circle cx="14.5411" cy="6.79708" r="0.95202" stroke="white" />
                <circle cx="11.8478" cy="6.79708" r="0.95202" stroke="white" />
                <circle cx="9.15417" cy="6.79708" r="0.95202" stroke="white" />
                <path
                  d="M5.8501 5.90908L5.8501 3.01346H8.75414V5.90908M5.8501 12.8577V20H8.75414V14.7306"
                  stroke="white"
                />
              </svg>
              <span>Home</span>
            </NavLink>
          </li>
          {account && (
            <li>
              <NavLink
                to="/earnings"
                className="d_flex ai_c"
                activeClassName="active"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="8" cy="8" r="7.5" stroke="#8D8D95" />
                  <path
                    d="M11 4.97552H5.6098V7.99222H10.3903V11.0243H5"
                    stroke="#8D8D95"
                  />
                  <path d="M8.04883 4.9756V2.92682" stroke="#8D8D95" />
                  <path d="M8.04883 13.0733V11.0245" stroke="#8D8D95" />
                </svg>
                <span>Earnings</span>
              </NavLink>
            </li>
          )}
          <li>
            <NavLink
              to="/competitions"
              className="d_flex ai_c"
              activeClassName="active"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="8.99108"
                  cy="8.99108"
                  r="7.99108"
                  stroke="#8D8D95"
                />
                <circle cx="9" cy="9" r="6" stroke="#8D8D95" />
                <path d="M9.16943 1.01782V2.60534" stroke="#8D8D95" />
                <path d="M9.16943 15.4125V17" stroke="#8D8D95" />
                <path d="M15.9312 5.06689L14.5563 5.86065" stroke="#8D8D95" />
                <path d="M3.48071 12.2731L2.10589 13.0669" stroke="#8D8D95" />
                <path d="M3.48071 5.75363L2.10589 4.95987" stroke="#8D8D95" />
                <path
                  d="M10.1673 12.1605H9.00894M7.85059 12.1605H9.00894M9.00894 12.1605V9.51532"
                  stroke="#8D8D95"
                />
                <path
                  d="M12 9.61872C12 11.2706 9.00905 11.2439 9.00905 9.49769C9.00905 11.2266 6.01807 11.2706 6.01807 9.61872C6.01807 7.96684 9.00905 5.81516 9.00905 5.81516C9.00905 5.81516 12 7.96684 12 9.61872Z"
                  stroke="#8D8D95"
                />
              </svg>
              <span>Competitions</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/events"
              className="d_flex ai_c"
              activeClassName="active"
            >
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.4716 6.6661L4.67839 6.07898L7.14869 8.35618L10.9213 12.3372L8.82474 13.1219L8.82473 13.1219L8.82181 13.123L6.1169 14.1547L6.1164 14.1549L3.82444 15.0318L3.82132 15.033L0.884843 16.1342L2.46424 12.1857L2.46719 12.1783L2.4699 12.1709L4.4699 6.67087L4.46992 6.67088L4.4716 6.6661Z"
                  stroke="#8D8D95"
                />
                <path
                  d="M8.91801 1C9.09287 1.96175 9.02293 4.25246 7.34424 5.72131M16 8.34426C15.1257 8.1694 13.0098 8.23934 11.541 9.91803M13.9016 2.57377V4.40984H12.3278L12.0656 5.98361H10.4918V7.55738H8.91801"
                  stroke="#8D8D95"
                />
              </svg>
              <span>Events</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/merch"
              className="d_flex ai_c"
              activeClassName="active"
            >
              <svg
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.8272 2.38272L11.0741 1L8.90123 3.96296L6.53086 1L2.97531 2.38272L1 7.12346L3.76543 7.91358V15.8148H14.037V7.91358L17 7.12346L14.8272 2.38272Z"
                  stroke="#8D8D95"
                />
                <rect
                  x="5.74072"
                  y="6.72839"
                  width="6.32099"
                  height="6.32099"
                  stroke="#8D8D95"
                />
                <path
                  d="M12.0617 11.4691L10.4815 10.0864L9.49381 11.0741L7.32097 9.09877L5.74072 10.679"
                  stroke="#8D8D95"
                />
              </svg>
              <span>Merch</span>
            </NavLink>
          </li>
          {/* 
			<li>
				<NavLink to="/perks" className="d_flex ai_c" activeClassName="active">
					<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M9 2L11.5392 6.50505L16.6085 7.52786L13.1086 11.335L13.7023 16.4721L9 14.32L4.29772 16.4721L4.89144 11.335L1.39155 7.52786L6.46077 6.50505L9 2Z" stroke="#8D8D95"/>
					</svg>
					<span>Perks</span>
				</NavLink>
			</li>
			*/}
        </ul>
      </nav>
    </div>
  )
}

export default Menu
