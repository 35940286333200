import React, {useEffect} from 'react';
import { Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';

import AOS from 'aos';
import 'aos/dist/aos.css';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import Earnings from './views/Earnings';
import Competitions from './views/Competitions';
import Events from './views/Events';
import Merch from './views/Merch';
import Perks from './views/Perks';
// import Mint from './views/Mint';

const App = () => {
  useEffect(() => {
    AOS.init({
      duration : 1000,
      offset: 10
    })
  });
  return (
    <Switch>
      <AppRoute exact path="/earnings" component={Earnings} layout={LayoutDefault} />
      <AppRoute exact path="/competitions" component={Competitions} layout={LayoutDefault} />
      <AppRoute exact path="/events" component={Events} layout={LayoutDefault} />
      <AppRoute exact path="/merch" component={Merch} layout={LayoutDefault} />
      <AppRoute exact path="/perks" component={Perks} layout={LayoutDefault} />
      <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
    </Switch>
  );
}

export default App;
